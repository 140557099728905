import { BounceLoader } from 'react-spinners'
import React from 'react'
import './Animation.css'
import '../logo.svg'
import { LogoSvg } from './LogoSvg'

export const Animation: React.FC = () => {
  return (
      <div className="anim_container">
        <div className="anim_small_container">
          <div className="anim_header_container">
            <div className="anim_containerLogo">
              <div className="anim_containerLogo_image">
                <LogoSvg />
              </div>
              <div className="anim_containerLogo_text">SideMind</div>
            </div>
          </div>
          <BounceLoader color='#FFF'/>
          <div className="anim_mainPromoText">Loading...</div>
        </div>
      </div>
  )
}
