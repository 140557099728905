/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { DocumentData } from 'firebase/firestore'
import React, { useCallback } from 'react'
import { EmptyAvatar } from './EmptyAvatar'
import { LogoSvg } from './LogoSvg'
import './AvatarPreview.css'

interface IAvatarPreviewProps {
  data: DocumentData | undefined
  bID: string | null
  uri: string | undefined
  defaultAvatarSend: boolean
  startingtAvatarSend: boolean
}

export const AvatarPreview: React.FC<IAvatarPreviewProps> = ({ data, bID, uri, defaultAvatarSend, startingtAvatarSend }) => {
  const linkToAppStore = 'https://apps.apple.com/us/app/sidemind-personal-ai-chatbots/id1660825704'
  const handlerMoveToApp = useCallback(() => {
    const generatedLink = window.innerWidth < 720 ? `https://textgenie.page.link/?link=https://sidemind.app/chat/${bID}/${defaultAvatarSend}/${startingtAvatarSend}/&isi=1660825704&ibi=com.textgenie.TextGenie&efr=1` : linkToAppStore
    window.location.replace(generatedLink)
  }, [bID])
  return (
    <div className="container">
      <div className="small_container">
        <div className="header_container">
          <div className="containerLogo">
            <div className="containerLogo_image">
              <LogoSvg/>
            </div>
            <div className="containerLogo_text">SideMind</div>
          </div>
          <p className="mainPromoText">Chat with this AI Mind</p>
        </div>
        <div className="mainPromoImage">
          {typeof uri === 'undefined'
            ? <EmptyAvatar />
            : <img
            className="mainPromoImage_source"
            src={uri}
            alt="empty_avatar"
          />}
        </div>
        <div className="mainDescriptionContainer">
          <div className="mainDescriptionContainer__name">{data?.name}</div>
          <div className='mainDescriptionContainer__texts'>{data?.tagLine}</div>
        </div>
        <div className='mainButtonWrapper' onClick={handlerMoveToApp}>
          <div className="mainButton">Chat now</div>
        </div>
      </div>
    </div>
  )
}
