/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import './logo.svg'
import React, { useEffect, useState } from 'react'
import { initializeApp } from 'firebase/app'
import { getFirestore, getDoc, doc, DocumentData } from 'firebase/firestore'
import './assets/icons/fonts/pro-display/SFPRODISPLAYREGULAR.OTF'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { DeletedAvatar } from './components/DeletedAvatar'
import { Animation } from './components/Animation'
import { AvatarPreview } from './components/AvatarPreview'
import { getAuth, signInAnonymously } from 'firebase/auth'
import axios from 'axios'

declare module 'axios' {
  export interface AxiosRequestConfig {
    alias: string
  }
}

const address = window.location.href
const AvatarPostReview = () => {
  const shortUrlAlias = address.replace('https://sidemind.app/?id=', '')
  // const [bID, setBID] = useState(queryParams.get('https://sidemind.app/?bID='))
  const [bID, setBID] = useState<string | null>(null)
  const [defaultAvatarSend, setDefaultAvatarSend] = useState(false)
  const [startingtAvatarSend, setStartingAvatarSend] = useState(false)
  const [data, setData] = useState<DocumentData | undefined>(undefined)
  const [uri, setUri] = useState<string | undefined>('')
  const [animation, setAnimation] = useState(false)

  const firebaseConfig = {
    apiKey: 'AIzaSyD-I-0JsR8aFmE5Iea2xJ5BY1msTNJ6Smk',
    authDomain: 'sidemind-aa533.firebaseapp.com',
    databaseURL: 'https://sidemind-aa533-default-rtdb.firebaseio.com',
    projectId: 'sidemind-aa533',
    storageBucket: 'sidemind-aa533.appspot.com',
    messagingSenderId: '648645022510',
    appId: '1:648645022510:web:385e84729e2741c218e0bf',
    measurementId: 'G-3LK82K1WW1'
  }
  const app = initializeApp(firebaseConfig)
  const db = getFirestore(app)
  const storage = getStorage()
  const auth = getAuth()
  async function Sign () {
    const signIn = await signInAnonymously(auth)
    return signIn
  }

  useEffect(() => {
    async function fetchData () {
      try {
        setAnimation(true)

        axios.defaults.headers.common.Authorization = 'Bearer Rm7eJ0B0QAFX8YA5zSaLOJJ37r605NsJ35pcxyuHoqgP1iov6i3Nrx0n8iI2'
        const tinyUrlResponse = await axios.get(`https://api.tinyurl.com/alias/tinyurl.com/${shortUrlAlias}`, {
          alias: `${shortUrlAlias}`
        })
        const queryParams = new URLSearchParams(tinyUrlResponse.data.data.url)
        await Sign()
        // const ID = queryParams.get('https://sidemind.app/?bID=')
        let ID = tinyUrlResponse.data.data.url.replace('https://sidemind.app/?bID=', '')
        const index = ID.indexOf('&')
        ID = ID.slice(0, index)
        setBID(ID)
        const dFParam = /^true$/i.test(queryParams.get('dF') ?? '')
        const sAParam = /^true$/i.test(queryParams.get('sA') ?? '')
        setDefaultAvatarSend(dFParam)
        setStartingAvatarSend(sAParam)
        if (dFParam) {
          const docRef = doc(db, 'botsList', `${sAParam ? 'Starting_v2' : 'Common_v2'}`)
          const docSnap = await getDoc(docRef)
          const data = docSnap.data()
          if (sAParam) {
            console.log('Starting bots')
            const avatar = data?.Starting.find((el: any) => Number(ID) === el.id)
            setData(avatar)
            setUri(avatar.imagePath)
          } else {
            console.log('Default bots')
            console.log(ID)
            for (const element of Object.values(data ?? {})) {
              const avatar = element.find((el: any) => {
                return Number(ID) === el.id
              })
              if (avatar) {
                setData(avatar)
                setUri(avatar.imagePath)

                break
              }
            }
          }
        } else {
          console.log('Custom bots')
          const docRef = doc(db, 'customBotsList', `${ID}`)
          const docSnap = await getDoc(docRef)
          const data = docSnap.data()
          console.log(data)
          let uri
          if (data?.imagePath) {
            const pathReference = ref(storage, data.imagePath)
            uri = await getDownloadURL(pathReference)
          }
          console.log(data)
          setData(data)
          setUri(uri)
        }

        setAnimation(false)
      } catch (e) {
        console.log(e)
        setAnimation(false)
      }
    }
    void fetchData()
  }, [bID])

  return (
    data
      ? <AvatarPreview
                  data={data}
                  bID={bID}
                  uri={uri}
                  defaultAvatarSend={defaultAvatarSend}
                  startingtAvatarSend={startingtAvatarSend}
                   />
      : animation
        ? <Animation/>
        : <DeletedAvatar/>
  )
}

export default AvatarPostReview
