import React, { useCallback } from 'react'
import './DeletedAvatar.css'
import '../logo.svg'
import { EmptyAvatarCrossSvg } from './EmptyAvatarCrossSvg'
import { LogoSvg } from './LogoSvg'

export const DeletedAvatar: React.FC = () => {
  const handlerMoveToApp = useCallback(() => {
    const generatedLink = 'https://apps.apple.com/us/app/sidemind-personal-ai-chatbots/id1660825704'
    window.open(generatedLink, 'mozillaTab')
  }, [])

  return (
      <div className="container">
        <div className="small_container">
          <div className="header_container">
            <div className="containerLogo">
              <div className="containerLogo_image">
                <LogoSvg/>
              </div>
              <div className="containerLogo_text">SideMind</div>
            </div>
          </div>
          <div className="mainPromoImage">
            <EmptyAvatarCrossSvg/>
          </div>
          <div className="mainDescriptionContainer">
          <p className="mainPromoText">Mind not available</p>
            <div className='mainDescriptionContainer__texts'>The link is either expired or invalid. </div>
          </div>
          <div className='mainButtonWrapper' onClick={handlerMoveToApp}>
            <div className="mainButton">Continue to SideMind</div>
          </div>
        </div>
      </div>
  )
}
