import React from 'react'

export const EmptyAvatar: React.FC = () => {
  return (
        <svg
            width={108}
            height={108}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M54 108c29.541 0 54-24.512 54-54C108 24.46 83.488 0 53.947 0 24.46 0 0 24.459 0 54c0 29.488 24.512 54 54 54Z"
              fill="#9A9EA8"
            />
            <path
              d="M53.734 99.063c-12.076 0-24.31-4.957-32.379-13.553 5.696-8.965 18.088-14.239 32.38-14.239 14.185 0 26.683 5.168 32.431 14.239-8.121 8.596-20.303 13.552-32.432 13.552Zm0-36.756C43.61 62.2 35.647 53.764 35.647 42.426c-.053-10.653 8.015-19.512 18.087-19.512 10.125 0 18.088 8.86 18.088 19.512 0 11.338-7.91 19.986-18.088 19.88Z"
              fill="#fff"
            />
          </svg>
  )
}
