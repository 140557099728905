import React from 'react'

export const EmptyAvatarCrossSvg: React.FC = () => {
  return (
    <svg
    width={108}
    height={109}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)">
      <path
        d="M54 109c29.541 0 54-24.512 54-54C108 25.46 83.488 1 53.947 1 24.46 1 0 25.459 0 55c0 29.488 24.512 54 54 54Z"
        fill="#9A9EA8"
      />
      <path
        d="M53.734 100.062c-12.076 0-24.31-4.956-32.379-13.552 5.696-8.965 18.088-14.239 32.38-14.239 14.185 0 26.683 5.168 32.431 14.239-8.121 8.596-20.303 13.552-32.432 13.552Zm0-36.755C43.61 63.2 35.647 54.764 35.647 43.426c-.053-10.653 8.015-19.512 18.087-19.512 10.125 0 18.088 8.86 18.088 19.512 0 11.338-7.91 19.986-18.088 19.88Z"
        fill="#fff"
      />
      <path
        stroke="#9A9EA8"
        strokeWidth={6}
        d="m21.738 20.379 65.385 65.384M19.121 23.994l65.385 65.385"
      />
      <path
        stroke="#fff"
        strokeWidth={4}
        strokeLinecap="round"
        d="m21.571 23.372 62.556 62.556"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(0 .5)"
          d="M0 0h107.578v107.631H0z"
        />
      </clipPath>
    </defs>
  </svg>
  )
}
